
export enum EnumCountry {
    Country = -1,
    Bangladesh = 1,
    India = 2,
    Packstan = 3,
    Nepal = 4,
}

export enum EnumDistrict {
    District = -1,
    Bagerhat = 5,
    Bandarban = 6,
    Barguna = 7,
    Barisal = 8,
    Bhola = 9,
    Bogra = 10,
    Brahmanbaria = 11,
    Chandpur = 12,
    Chapainawabganj = 13,
    Chittagong = 14,
    Chuadanga = 15,    
    Comilla = 3,
    'Cox \'s\ Bazar' = 16,
    Dhaka = 17,
    Dinajpur = 2,
    Faridpur = 18,
    Feni = 19,
    Gaibandha = 20,
    Gazipur = 21,
    Gopalganj = 22,
    Habiganj = 23,
    Jamalpur = 24,
    Jessore = 25,
    Jhalokati = 26,
    Jhenaidah = 27,   
    Joypurhat = 4,
    Khagrachhari = 28,
    Khulna = 29,
    Kishoreganj = 30,
    Kurigram = 31,
    Kushtia = 32,
    Lakshmipur = 33,
    Lalmonirhat = 34,
    Madaripur = 35,
    Magura = 36,
    Manikganj = 37,
    Meherpur = 38,
    Moulvibazar = 39,
    Munshiganj = 40,
    Mymensingh = 41,
    Naogaon = 42,
    Narail = 43,
    Narayanganj = 44,
    Narsingdi = 45,
    Natore = 46,
    Netrokona = 47,
    Nilphamari = 48,
    Noakhali = 49,
    Pabna = 50,
    Panchagarh = 51,
    Patuakhali = 52,
    Pirojpur = 53,
    Rajbari = 54,
    Rajshahi = 55,
    Rangamati = 56,
    Rangpur = 57,
    Satkhira = 58,
    Shariatpur = 59,
    Sherpur = 60,
    Sirajganj = 61,
    Sunamganj = 62,
    Sylhet = 63,    
    Tangail = 1,
    Thakurgaon = 64   
}

export enum EnumCity {
    City= -1,
    Dhaka = 1,
    Syllet = 2,
    Rajshahi = 3,
    Cittagong = 4,
}

export enum EnumPoliceStation {
    'Police Station' = -1,
    'Police Station one'  = 1,
    'Police Station two'  = 2,
    'Police Station three'  = 3,
    'Police Station four'  = 4
}

export enum  EnumStatus {
    Status = -1,
    Active = 1,
    Inactive = 2,
    Deleted = 3,
    Dead = 4
}
export enum EnumWeeklyReport{
    Recharge = 1,
    Dispense = 2,
    Registration = 3,
}


export enum ErrorCode {
    INTERNAL_SERVER_ERROR = 500,
    BAD_REQUEST = 400,
    NOT_FOUND = 404,
    UNAUTHENTICATE = 401,
    UNAUTHORIZED = 403,
    TOKEN_EXPIRED = 402,
    SESSION_EXPIRED = 419,
    MISSING_TOKEN = 417,
    INVALID_TOKEN_FORMAT = 406
}


export enum  EnumGender {
    Gender = -1,
    Male = 0,
    Female = 1
}

export enum  EnumParentType {
   'Parnent Type' = -1,
    Father = 1,
    Mother = 2,
    Spouse = 3
}

export enum  EnumAddressType {
    present = 1,
    permanent = 2,
    billing = 3,
    refernce = 4
}


export enum EnumAccountSearch {
    name =  1,
    accountNo = 2,
    mobileNo = 3,
    refNo=4
}


export enum EnumCardSearch {
    assignTo = 1,
    cardNumber = 2
}

export enum EnumActionType {
    Add = 1,
    Update = 2,
    Delete = 3
}

export enum EnumActionName {
        Account = 1,
        Card = 2,
        Recharge = 3,
        Payment = 4,
        Transaction = 5,
        WASARechargeRequest = 6,
        SalesForceReport = 7,
        ATM = 8,
        Pump = 9,
        Vendor = 10,
        Zone = 11,
        User = 12,
        WaterCosting=13,
        WaterTransaction=14
}


export enum EnumRechargeType {
    "Hand Cash" = 1,
    UCash = 2,
    Bkash = 3,
    DBBL = 4
}

export enum EnumAccountType {
    General = 1,
    Gold= 2,
    Silver = 3,
    Platinum = 4
}

export const Message = {
    CardAddSuccessful : 'Card added successful',
    AccountAddSuccessful : 'Account added successful',
    DeviceAddSuccessful : 'Device added successful',
    BoothAddSuccessful : 'Booth added successful',
    RechargeAddSuccessful : 'Recharge  successful',
    ZoneAddSuccessful : 'Zone Added  successful',
    VendorAddSuccessful : 'Vendor Added  successful',

    UserAddSuccessful : 'User added successful',

    CardUpdateSuccessful : 'Card updated successful',

    WaterCostSuccessful : 'Water Cost updated successful',

    CardAssignSuccessful : 'Card assigned successful',
    AccountUpdateSuccessful : 'Account updated successful',
    AccountDeletedSuccessful : 'Account delete successful',
    DeviceUpdateSuccessful : 'Device updated successful',
    BoothUpdateSuccessful : 'Booth updated successful',
    ZoneUpdateSuccessful : 'Zone updated successful',
    VendorUpdateSuccessful : 'Vendor updated successful',

    RechargeUpdateSuccessful : 'updated  successful',
    UserUpdateSuccessful : 'User updated successful',

    ChangePasswordSuccessful : 'Password changed successful',
    ResetPasswordSuccessful : 'Password reset successful',
    PasswordNotMatched : 'Password Not Matched',
    DeviceUnauthorizedSuccessful : 'Device successfully de-authorized',
    DeviceAuthorizedSuccessful : 'Device successfully authorized',
    TransactionRefundSuccessful : 'Transaction successfully refund',
};

export const WaterRelatedDiseasesOccurrence = {
    Frequently : 1,
    Sometimes : 2,
    "Not often" : 3,
    Never: 4
};

export enum DailyAmountOfWater {
    "less than 5" =  1,
    "06to12" = 2,
    "11to20" = 3,
    "21to30" = 4,
    "31to40" = 5,
    "41to50" = 6,
    "more than 60"= 7,
};

export enum CurrentWaterSupplyStatus {
    "Extremely safe and clean"= 1,
    "Mostly safe and clean"= 2,
    "Somewhat safe and clean"=3,
    "Not very safe and little dirty"= 4,
    "Extremely unsafe and very dirty"= 5,
}

export enum DeliveryPreference {
    Delivery = 1,
    Pickup = 2
}

export enum EnumIdentityType {
    Nid = 1,
    BirthCertificate = 2,
    PassportNo = 3,
    DrivingLicense = 4
}

export enum Status {
    Status = -1,
    Active = 1,
    Inactive = 2,
    Delete = 3,
    Block = 4,
    Dead = 5
}
export enum RechargeUsing {
    AccountNo = 1,
    Inactive = 2,
    Delete = 3
}

export enum PaginationType {
    Pagination = 1,
    Export = 2
}
